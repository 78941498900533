<template id="error">
      <div align="center">
        <div class="card col-md-8 rounded shadow-lg" style="text-align: left">
          <div class="card-body">
            <div class="card-title">
              <h1>Resultado de la búsqueda</h1>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="alert alert-primary col-md-12">No se ha encontrado la PQRS. Verifique los datos suministrados.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  created(){

  }    
}
</script>
